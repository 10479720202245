<template>
    <div id="footer">
        <div class="mt-5">
            <div class="row justify-content-around">
                <div class="col-lg-2 lg">
                    <img class="w-100" src="./../assets/images/logo_2.png">
                </div>
                <div class="col-lg-auto col-md-5 m-3 text-start">
                    <h5 class="text-purple">{{ $t('contact') }}</h5>
                    <h6 class="text-gray">{{ $t('footer1') }}</h6>
                    <p class="text-gray m-0">{{ $t('footer2') }}</p>
                    <p class="text-gray m-0">{{ $t('footer3') }}</p>
                    <a class="text-purple m-0" href="mailto:omniomni@omni-counsel.com?subject=OMNIcounsel 聯絡我們">omniomni@omni-counsel.com</a>
                </div>
                <div class="col-lg-auto col-md-5 m-3 text-start">
                    <router-link to="/" class="text-purple">{{ $t('home') }}</router-link><br>
                    <router-link to="/about" class="text-purple">{{ $t('about') }}</router-link><br>
                    <router-link to="/" class="text-purple">{{ $t('service') }}</router-link>
                    <img class="w-75 ms-auto sm" src="./../assets/images/logo_2.png">
                </div>
            </div>
        </div>
        <hr class="bg-purple mt-5">
        <p class="text-center text-purple">{{ $t('footer') }}</p>
    </div>
</template>
<style lang="scss">
@import '@/scss/theme.scss';
#footer{
    @include media-pc{
        margin-left: 15rem;
        margin-right: 15rem;
    }
    @include media-lgnb{
        margin-left: 2rem;
        margin-right: 2rem;
    }
    img{
        width: 30%;
        @include media-mobile{
            width: 55%;
        }
    }
}
</style>
