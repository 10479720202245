<template>
  <div id="header">
    <nav class="navbar navbar-expand-xl navbar-light bg-white">
      <div class="container-fluid">
        <!-- Logo -->
        <router-link to="/" class="navbar-brand">
          <img class="w-100" src="./../assets/images/logo_1.png">
        </router-link>
        <!-- Mobile button -->
        <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarContent"
            aria-controls="navbarContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div ref="menuCollapse" class="collapse navbar-collapse row" id="navbarContent">
            <!-- Head -->
            <ul class="navbar-nav mx-auto col-xl-7 col-lg-12">
                <li class="nav-item w-25 h4" v-for="(head, i) in headers" :key="head">
                    <router-link class="nav-link px-4 text-purple"
                    :class="{'border-left': i != 0}"
                    :to="{ path: '/' + (head == 'home' || head == 'service' ? '' : head)}"
                    >{{ $t(head) }}</router-link>
                </li>
            </ul>
            <!-- Language -->
            <ul id="lan-bar" class="mt-auto mb-auto col-xl-3 col-lg-12">
                <li
                v-for="(locale, i) in locales"
                :key="locale"
                @click="switchLocale(locale)">
                <span role="button" class="px-1 text-purple"
                    :class="{'border-left': i != 0}"
                >{{ $t(locale) }}</span>
                </li>
            </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import { Collapse } from 'bootstrap'
export default {
  name: 'Header',
  data () {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','),
      headers: ['home', 'about', 'service', 'contact']
    }
  },
  methods: {
    switchLocale (locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale
      }
    }
  },
  mounted () {
    // eslint-disable-next-line no-new
    new Collapse(this.$refs.menuCollapse, { toggle: false })
  }
}
</script>

<style lang="scss">
@import '@/scss/theme.scss';
#header{
    @include media-pc{
        margin-left: 15rem;
        margin-right: 15rem;
    }
    @include media-lgnb{
        margin-left: 2rem;
        margin-right: 2rem;
    }
    @include media-nb{
        margin-left: 0;
        margin-right: 0;
    }
    .navbar-brand{
        @include media-mobile{
            width: 50%;
        }
    }
    .border-left{
        border-left: 1px solid $purple !important;
    }
    ul{
        display: flex;
        list-style: none;
        @include media-pc{
            flex-direction: row;
        }
        a{
            color: $dark-purple !important;
        }
    }
    .navbar-nav{
        @include media-nb{
            flex-direction: column;
            padding-left: 40px;
            .border-right{
                border-right: none !important;
            }
        }
        .nav-item{
            text-align: center;
            @include media-nb{
                text-align: left;
                .border-left {
                    border-left: none !important;
                }
            }
            @include media-mobile{
                width: 100% !important;
            }
        }
    }
    #lan-bar{
        justify-content: flex-end;
        @include media-nb{
            padding-top: 1rem;
            padding-left: 40px;
            justify-content: normal;
        }
    }
}
</style>
