export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于我们"])},
  "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联络我们"])},
  "zh-TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["繁體中文"])},
  "zh-CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["简体中文"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright © OMNI COUNSEL All rights reserved"])},
  "footer1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["远京企业股份有限公司 MYGUARD CO., LTD."])},
  "footer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桃园市龟山区文兴路146号3楼之2"])},
  "footer3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+886-3-327-0080"])},
  "omni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的表情真的代表您的心？"])},
  "omni1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["霎时之诚-脸部瞬间的微表情不经意间透露了您的真实情绪"])},
  "omni2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMNIcounsel®利用emotion AI人工智慧快速捕捉0.2秒瞬间闪现的细微表情，精准解析复杂的面部情绪，并量化计算出各种情绪的占比。它不受人类主观影响，用科技解密真实情绪，发掘更多深层心理沟通资讯，也让精准的美学治疗变得可能。"])},
  "omni3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未来已经到来，OMNIcounsel®就在您的手边。"])},
  "icon1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["功能性解剖学为基础"])},
  "icon2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["精准量化研究实战两相宜"])},
  "icon3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合併心理学动作单元分析"])},
  "tech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在科学研究下，"])},
  "tech1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发现人类的表情至少超过10,000种"])},
  "tech2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要花多少时间学习、观察、客观的判别？"])},
  "tech3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["何妨交给OMNIcounsel®？帮您以简驭繁，让您只需专注在您的专业。"])},
  "tech4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMNIcounsel®基于美国心理学家保罗· 艾克曼(Paul Ekman) 所提出的面部动作编码系统，将复杂的脸部表情分析归纳出不同的情绪类别。再加上以大数据训练的人工智慧，哪怕是不易察觉的细微表情，OMNIcounsel®都可以精准捕捉、并将复杂的动作单元化繁为简，转为量化的分析结果。"])},
  "ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["弹指之间，深层洞察"])},
  "ai1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI辅助 专业随行"])},
  "ai1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI辅助"])},
  "ai1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专业随行"])},
  "adv1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数位沟通力"])},
  "adv2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透过AI协助解读脸部表情背后潜藏的情绪，从而搭建精准沟通的桥樑！"])},
  "adv3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数位竞争力"])},
  "adv4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["除了微细表情明察秋毫，更可应用于专注度评估，加速工作效率。"])},
  "adv5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["美感执行力"])},
  "adv6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["精准的分析，让量身打造的美学治疗变得可能，以达成最少干预、最佳成果的自然改善。"])},
  "ai3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMNI随行"])},
  "ai4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["增加专业AI形象"])},
  "dentisit1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["美容医学从业人员"])},
  "dentisit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["精准掌握患者脸部变化，规划最合适的治疗方案！"])},
  "Finance1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["财经相关人员"])},
  "Finance2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["协助支持你的第六感，找出最值得信赖的伙伴！"])},
  "consult1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["心理谘商师"])},
  "consult2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["精准客观判断表情学，掌握更多观察时间。"])},
  "HR1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人力资源 HR"])},
  "HR2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全新智慧AI科技，不苦恼的找出适合的同事伙伴。"])},
  "Device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skin Diagnostic Device"])},
  "Device1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A multi-modality face illumination and imaging system"])},
  "light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["27"])},
  "light1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["27"])},
  "diffuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18"])},
  "diffuse1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diffuse"])},
  "polarized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09"])},
  "polarized1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["polarized"])},
  "wavelengths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03"])},
  "wavelengths1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wavelengths"])},
  "Daylight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daylight"])},
  "Daylight1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["｜ 4000˚k"])},
  "UV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["True UV"])},
  "UV1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["｜ 365nm"])},
  "Wood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulated Wood’s "])},
  "Wood1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["｜ 365nm+405nm"])},
  "mode1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日光"])},
  "mode2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表面纹理"])},
  "mode3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["色素分析"])},
  "mode4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["敏感泛红"])},
  "mode5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平行偏振光"])},
  "mode6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交叉偏振光"])},
  "mode7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紫外光"])},
  "mode8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["伍德光"])},
  "patent1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的注册及专利"])},
  "patent2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运用于美容医疗的人工智能辅助评估方法及系统与电子装置"])},
  "CPFA1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与业界领导品牌合作"])},
  "CPFA2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works?"])},
  "CPFA3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C"])},
  "CPFA4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ustomized"])},
  "CPFA5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P"])},
  "CPFA6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recision"])},
  "CPFA7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["F"])},
  "CPFA8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["acial"])},
  "CPFA9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A"])},
  "CPFA10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ssessment"])},
  "CPFA11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A novel"])},
  "CPFA12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI"])},
  "CPFA13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-assisted protocol to unveil and"])},
  "CPFA14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantify the facial"])},
  "CPFA15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["micro-expressions"])},
  "CPFA16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for"])},
  "CPFA17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["advanced aesthetic treatment"])},
  "system1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMNI AI脸部辨识系统"])},
  "system2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透过微表情，迎接心时代"])},
  "system3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可根据专业别客制化软体，欢迎与我们联系"])},
  "system4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT OMNI"])},
  "about1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于"])},
  "about2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["诚信，"])},
  "about3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们追求以人为本的本质"])},
  "about4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["因此我们希望透过OMNIcounsel®，基于科学基础提供客观的辅助，支持各领域与产业的评估工作，从精准判断进而改善作业流程，让一切变得更省心。"])},
  "develop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发展"])},
  "2016a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2016"])},
  "2016b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-AI Era"])},
  "2016c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2016/10/22-23"])},
  "2016d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual Meeting of Taiwan Academy of Facial Plastic and Reconstructive Surgery (TAFPRS), Taipei. Invited Speech Approach to Overall Facial Harmony by App Platform"])},
  "2018a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2018"])},
  "2018b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI：Pre-OMNICounsel Era"])},
  "2018c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2018/5/4-6 "])},
  "2018d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aesthetic & Anti-Aging Medicine World Congress Asia (AMWC Asia), Taipei. Invited Speech What your facial features are saying? Application of emotion A.I. system"])},
  "2018e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2018/5/2-5 "])},
  "2018f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["美沃斯国际医学美容大会，武汉：美人致谘询教育首次发表"])},
  "2018g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2018/11/9-10 "])},
  "2018h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第十四届中国皮肤科医师年会暨全国美容皮肤科学大会，广州：专题演讲，以功能性、动态性、解剖学作为脸部医美评估的新概念"])},
  "2019a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2019"])},
  "2019b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI: OMNICounsel Era"])},
  "2019c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2019/4/4-6"])},
  "2019d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2019年 美容暨抗衰老世界高峰会(AMWC)，蒙地卡罗（全世界与会人数最多的美容医学大会，与会人数>12000人）：首次公开"])},
  "2019e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2019/5/10-12"])},
  "2019f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2019年世界美容高峰会亚洲大会"])},
  "2019g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（亚洲与会人数最多的美容医学大会），台北：专题演讲"])},
  "2019h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2019/10/24-27"])},
  "2019i": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2019年美国皮肤外科医学会年会"])},
  "2019j": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（皮肤外科最高盛会），芝加哥：电子海报"])},
  "2019k": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2019/11/8"])},
  "2019l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第十五届中国医师协会皮肤科医师年会，厦门：专题演讲"])},
  "2020a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020"])},
  "2020b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI: OMNI Counsel Era"])},
  "2020c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020/1/30-2/1 "])},
  "2020d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020年英卡思国际美容整形暨皮肤抗衰老医学研究大会"])},
  "2020e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(世界前两大的美容医学大会）"])},
  "2020f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A novel AI-assisted protocol to unveil and quantify the facial micro-expressions for advanced aesthetic treatment: Integration of AI technology in my aesthetic practice"])},
  "2020g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020/2/6-9"])},
  "2020h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第九届颜面整形外科世界高峰会"])},
  "2020i": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（国际颜面整形外科四年一度的世界大会），台北：专题演讲"])},
  "2020j": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020.3"])},
  "2020k": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plast Reconstr Surg Glob Open 2020 Mar 11;8(3):e2688. "])},
  "2020l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published paper"])},
  "2020m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customized Precision Facial Assessment: An AI-assisted Analysis of Facial Micro-expressions for Advanced Aesthetic Treatment  doi: 10.1097/GOX.0000000000002688 "])},
  "2020n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020/3/20-24"])},
  "2020o": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020年美国皮肤科医学会年会，丹佛"])},
  "2020p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（国际皮肤科年度最大盛会，与会人数 >16000人。因COVID-19取消）"])},
  "2020q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020/8/28-30"])},
  "2020r": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020年五大洲世界高峰会(5CC)，巴赛隆纳（因COVID-19改为线上会议)：大会主演讲。 How AI-assisted Facial Analysis Aids in Precisional Aesthetic Treatment"])},
  "2020s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020/11/14-15"])},
  "2020t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020年台湾美容外科医学会，台北：专题演讲"])},
  "2020u": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implication of AI in my aesthetic practice: A Taiwan perspective"])},
  "2020v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020/11/16-20"])},
  "2020w": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020年韩国皮肤科协会国际美容皮肤病学术研讨会，线上会议：OmniCounsel应用于演讲主题 Forehead wrinkles revisited: An anatomy-based, functional approach in Asian "])},
  "2021a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021"])},
  "2021b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021/1"])},
  "2021c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OBSERV®全脸皮肤影像检测仪，授权合作"])},
  "2021d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021/7/22-23"])},
  "2021e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021年泰国皮肤科医学会年会：受邀演讲"])},
  "omnifirst1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMNI 是最初的起点"])},
  "omnifirst2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMNIcounsel®会持续不断地与不同的产业推出各式应用场景，敬请期待不同的火花。就如同网路改变世界、智慧型手机改变了您我的行为。现在还无法完全被定义的可能，即将陪伴在您我的身边。"])},
  "omnifirst3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未来已经来到，OMNIcounsel®就在您的身边。"])},
  "omnifirst4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["让我们一起见证转变的时刻，共同迈进到下一个新心组合当中。"])}
}