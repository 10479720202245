<template>
  <Header/>
  <router-view/>
  <Footer/>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  data: () => {
    return {
      country: 'US',
      region: 'CA'
    }
  },
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="scss">
@import '~bootstrap/scss/bootstrap';
@import '@/scss/theme.scss';
#app {
  width: 100vw;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
a {
  text-decoration: none;
}
</style>
