<template>
  <div id="about">
    <div class="ptob py-4">
      <h2 class="text-white" data-aos="fade-down">{{ $t('about1') }}</h2>
      <img class="logo-w" src="./../assets/images/OMNI_logo_W.png" data-aos="fade-down" data-aos-delay="300">
    </div>

    <div class="img-with-text">
      <img id="img1" class="w-100" src="./../assets/images/about_1.jpg">
      <div id="text1" class="text-on-img text-white" data-aos="fade-right">
        <h3 class="text-white mb-5">{{ $t('about2') }}{{ $t('about3') }}</h3>
        <p class="text-white">{{ $t('about4') }}</p>
      </div>
    </div>

    <div class="ptob py-4">
      <img class="logo-w" src="./../assets/images/OMNI_logo_W.png" data-aos="fade-down">
      <h2 class="text-white" data-aos="fade-down" data-aos-delay="300">{{ $t('develop') }}</h2>
    </div>

    <div id="history" class="btob py-4">
      <div class="row justify-content-center px-5">
        <div class="col-5 lg"></div>
        <div class="col-lg-5 col-md-10 ps-5 vline text-start">
          <!-- 2016 -->
          <div class="position-relative mt-4">
            <h3 class="text-purple text-start mb-0" data-aos="fade-left">{{ $t('2016a') }}</h3>
            <h3 class="text-purple text-start mb-3" data-aos="fade-left">{{ $t('2016b') }}</h3>
            <h5 class="text-purple text-start time-dot-left" data-aos="fade-left">{{ $t('2016c') }}</h5>
            <p class="text-gray text-start" data-aos="fade-left">{{ $t('2016d') }}</p>
            <img id="img2016" class="time-img-left" src="./../assets/images/time_pic_1.png" data-aos="flip-right">
          </div>
          <!-- 2018 -->
          <div class="position-relative mt-4">
            <h3 class="text-purple text-start mb-0" data-aos="fade-left">{{ $t('2018a') }}</h3>
            <h3 class="text-purple text-start mb-3" data-aos="fade-left">{{ $t('2018b') }}</h3>
            <h5 class="text-purple text-start time-dot-left" data-aos="fade-left">{{ $t('2018c') }}</h5>
            <p class="text-gray text-start" data-aos="fade-left">{{ $t('2018d') }}</p>
            <img id="img2018" class="time-img-left" src="./../assets/images/time_pic_2.png" data-aos="flip-right">

            <h5 class="text-purple text-start time-dot-left mt-3" data-aos="fade-left">{{ $t('2018e') }}</h5>
            <p class="text-gray text-start" data-aos="fade-left">{{ $t('2018f') }}</p>

            <h5 class="text-purple text-start time-dot-left" data-aos="fade-left">{{ $t('2018g') }}</h5>
            <p class="text-gray text-start" data-aos="fade-left">{{ $t('2018h') }}</p>
          </div>
          <!-- 2019 -->
          <div class="position-relative mt-4">
            <div class="time-text-left w-100" data-aos="fade-right">
              <h3 class="text-purple mb-0">{{ $t('2019a') }}</h3>
              <h3 class="text-purple mb-3">{{ $t('2019b') }}</h3>
              <h5 class="text-purple time-dot-right">{{ $t('2019c') }}</h5>
              <p class="text-gray">{{ $t('2019d') }}</p>
            </div>
            <img id="img2019_1" class="mt-3" src="./../assets/images/time_pic_3.png" data-aos="flip-left">
            <div class="time-text-left w-100" data-aos="fade-right">
              <h5 class="text-purple time-dot-right">{{ $t('2019e') }}</h5>
              <p class="text-gray">{{ $t('2019f') }}<br>
              {{ $t('2019g') }}</p>
              <h5 class="text-purple time-dot-right">{{ $t('2019h') }}</h5>
              <p class="text-gray">{{ $t('2019i') }}<br>
              {{ $t('2019j') }}</p>
              <h5 class="text-purple time-dot-right">{{ $t('2019k') }}</h5>
              <p class="text-gray">{{ $t('2019l') }}</p>
            </div>
            <img id="img2019_2" class="mt-3" src="./../assets/images/time_pic_4.png" data-aos="flip-left">
          </div>
          <!-- 2020-->
          <div class="position-relative mt-4">
            <h3 class="text-purple text-start mb-0" data-aos="fade-left">{{ $t('2020a') }}</h3>
            <h3 class="text-purple text-start mb-3" data-aos="fade-left">{{ $t('2020b') }}</h3>
            <h5 class="text-purple text-start time-dot-left" data-aos="fade-left">{{ $t('2020c') }}</h5>
            <p class="text-gray text-start" data-aos="fade-left">{{ $t('2020d') }}<br>
            {{ $t('2020e') }}<br>
            {{ $t('2020f') }}</p>
            <img id="img2020_1" class="time-img-left" src="./../assets/images/time_pic_5.png" data-aos="flip-right">

            <h5 class="text-purple text-start time-dot-left mt-3" data-aos="fade-left">{{ $t('2020g') }}</h5>
            <p class="text-gray text-start" data-aos="fade-left">{{ $t('2020h') }} {{ $t('2020i') }}</p>

            <h5 class="text-purple text-start time-dot-left" data-aos="fade-left">{{ $t('2020j') }}</h5>
            <p class="text-gray text-start" data-aos="fade-left">{{ $t('2020k') }} {{ $t('2020l') }}
            <br style="font-size: 0.5rem">{{ $t('2020m') }}</p>
          </div>
          <div class="position-relative mt-4">
            <h5 class="text-purple text-start time-dot-left" data-aos="fade-left">{{ $t('2020n') }}</h5>
            <p class="text-gray text-start" data-aos="fade-left">{{ $t('2020o') }} {{ $t('2020p') }}</p>
            <img id="img2020_2" class="time-img-left" src="./../assets/images/time_pic_6.png" data-aos="flip-right">
            <h5 class="text-purple text-start time-dot-left" data-aos="fade-left">{{ $t('2020q') }}</h5>
            <p class="text-gray text-start" data-aos="fade-left">{{ $t('2020r') }}</p>
            <h5 class="text-purple text-start time-dot-left" data-aos="fade-left">{{ $t('2020s') }}</h5>
            <p class="text-gray text-start" data-aos="fade-left">{{ $t('2020t') }}
            <br>{{ $t('2020u') }}</p>
            <h5 class="text-purple text-start time-dot-left" data-aos="fade-left">{{ $t('2020v') }}</h5>
            <p class="text-gray text-start" data-aos="fade-left">{{ $t('2020w') }}</p>
          </div>
          <!-- 2021-->
          <div class="position-relative mt-4">
            <div class="time-text-left w-100" data-aos="fade-right">
              <h3 class="text-purple mb-0">{{ $t('2021a') }}</h3>
              <h5 class="text-purple time-dot-right">{{ $t('2021b') }}</h5>
              <p class="text-gray">{{ $t('2021c') }}</p>
              <h5 class="text-purple time-dot-right">{{ $t('2021d') }}</h5>
              <p class="text-gray">{{ $t('2021e') }}</p>
            </div>
            <img id="img2021" class="mt-3" src="./../assets/images/time_pic_7.png" data-aos="flip-left">
          </div>
        </div>
      </div>
    </div>
    <div class="img-with-text">
      <img id="img2" src="./../assets/images/about_4.png">
      <div id="text2" class="text-on-img text-start" data-aos="fade-right">
        <h3 class="text-white mb-4">{{ $t('omnifirst1') }}</h3>
        <p class="text-white">{{ $t('omnifirst2') }}</p>
        <h5 class="text-white">{{ $t('omnifirst3') }}</h5>
        <h5 class="text-white">{{ $t('omnifirst4') }}</h5>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted () {
    document.title = this.$t('about') + ' - OMNIcounsel'
  }
}
</script>
<style lang="scss">
@import '@/scss/theme.scss';
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@600&display=swap');
#about {
  .img-with-text{
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  .text-on-img{
    position: absolute
  }
  .ptob {
    background: linear-gradient(to right, $purple-two, $light-blue);
    h2 {
      font-weight: 400;
    }
  }
  .btob {
    background: linear-gradient(340deg, $light-blue, white 10%, white 90%, $light-blue);
  }
  .logo-w {
    @include media-pad {
      width: 50%;
    }
  }

  #text1 {
    top: 36%;
    left: 15%;
    width: 30%;
  }

  #history {
    h3, h5 {
      font-family: 'Source Serif Pro', serif;
    }
    h5 {
      margin-top: 1rem;
    }
  }

  .vline {
    border-image: linear-gradient($purple-two, $light-blue) 30 30;
    border-left: 3px solid;
  }

  .time-dot-left {
    position: relative;
    &:before {
      position: absolute;
      content: "";
      border-top: 2px solid $purple;
      width: 2.5rem;
      left: -50px;
      top: 12px;
    }
    &:after {
      position: absolute;
      content: "";
      background-color: $purple;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      left: -54px;
      top: 9px;
    }
  }
  .time-dot-right {
    position: relative;
    &:before {
      position: absolute;
      content: "";
      border-top: 2px solid $purple;
      width: 2.5rem;
      right: -50px;
      top: 12px;
    }
    &:after {
      position: absolute;
      content: "";
      background-color: $purple;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      right: -54px;
      top: 9px;
    }
    @include media-pad {
      &:before {
        left: -50px;
        right: auto;
      }
      &:after {
        left: -54px;
        right: auto;
      }
    }
  }

  .time-img-left {
    position: absolute;
    right: 120%;
    @include media-pad {
      position: relative;
      right: auto;
    }
  }
  .time-text-left {
    position: absolute;
    right: 42vw;
    text-align: end;
    p {
      text-align: end;
    }
    @include media-pad {
      position: relative;
      right: auto;
      text-align: start;
      p {
        text-align: start;
      }
    }
  }
  #img2016 {
    top: 58%;
    height: 100%;
    @include media-pad {
      top: auto;
      width: 80%;
      height: auto;
    }
  }
  #img2018 {
    top: 32%;
    height: 70%;
    @include media-pad {
      top: auto;
      width: 80%;
      height: auto;
    }
  }
  #img2019_1 {
    width: 60%;
    @include media-pad {
      width: 80%;
    }
  }
  #img2019_2 {
    width: 90%;
    @include media-pad {
      width: 80%;
    }
  }
  #img2020_1 {
    top: 20%;
    height: 80%;
    @include media-pad {
      top: auto;
      width: 80%;
      height: auto;
    }
  }
  #img2020_2 {
    top: 2%;
    height: 80%;
    @include media-pad {
      top: auto;
      width: 80%;
      height: auto;
    }
  }
  #img2021 {
    width: 100%;
    @include media-pad {
      width: 80%;
    }
  }
  #img2 {
    width: 100%;
    @include media-pad {
      width: 200%;
    }
  }
  #text2 {
    top: 36%;
    left: 15%;
    width: 30%;
    @include media-pad {
      width: 50%;
    }
    @include media-pad {
      width: 65%;
      top: 8%;
      left: 20%;
    }
  }
}
</style>
