<template>
  <div id="main">
    <div id="img1" class="img-with-text">
        <img class="w-100" src="./../assets/images/home_1.jpg">
        <div id="text1" class="text-on-img" data-aos="fade-right">
          <h2 class="text-white text-start">{{ $t('omni') }}</h2>
          <h4 class="text-white text-start lg">{{ $t('omni1') }}</h4>
          <p class="text-white lg">{{ $t('omni2') }}</p>
          <p class="text-white lg">{{ $t('omni3') }}</p>
        </div>
    </div>
    <div class="container sm mt-4">
        <h4 class="text-purple">{{ $t('omni1') }}</h4>
        <p class="text-gray sm">{{ $t('omni2') }}</p>
        <p class="text-gray sm">{{ $t('omni3') }}</p>
    </div>

    <div id="ptob" class="w-100 pt-5 pb-5">
        <div class="row">
            <div class="col-4 text-center border-right">
                <span class="icon icon-home_icon_1 text-white"></span>
                <h4 class="text-white mx-auto pt-3">{{ $t('icon1') }}</h4>
            </div>
            <div class="col-4 text-center border-right">
                <span class="icon icon-home_icon_2 text-white"></span>
                <h4 class="text-white mx-auto pt-3">{{ $t('icon2') }}</h4>
            </div>
            <div class="col-4 text-center">
                <span class="icon icon-home_icon_3 text-white"></span>
                <h4 class="text-white mx-auto pt-3">{{ $t('icon3') }}</h4>
            </div>
        </div>
    </div>

    <div class="img-with-text">
        <img class="w-100" src="./../assets/images/home_2_1.jpg">
        <div id="text2" class="text-on-img" data-aos="fade-left">
            <h2 class="text-purple text-start">{{ $t('tech') }}<br>{{ $t('tech1') }}</h2>
            <h4 class="text-purple text-start lg pb-5">{{ $t('tech2') }}</h4>
            <p class="text-gray lg">{{ $t('tech3') }}</p>
            <p class="text-gray lg">{{ $t('tech4') }}</p>
        </div>
        <div class="container sm mt-4">
            <h4 class="text-purple">{{ $t('tech2') }}</h4>
            <p class="text-gray sm">{{ $t('tech3') }}</p>
            <p class="text-gray sm">{{ $t('tech4') }}</p>
        </div>
    </div>

    <div class="img-with-text">
        <img class="w-100" src="./../assets/images/home_3.jpg">
        <div id="text3" class="text-on-img h-75 w-50">
            <h1 class="text-purple text-center">{{ $t('ai') }}</h1>
            <h2 class="text-purple text-center lg">{{ $t('ai1') }}</h2>
            <h4 class="text-purple text-center sm">{{ $t('ai1_1') }}</h4>
            <h4 class="text-purple text-center sm">{{ $t('ai1_2') }}</h4>
        </div>
    </div>

    <div class="row w-100 m-0">
      <div class="col-lg-4 col-md-12 p-0">
          <div class="d-flex reverse">
              <div class="col-12 p-0">
                  <img class="w-100" src="./../assets/images/home_4.jpg">
              </div>
              <div class="col-12 icon-text bg-light-gray">
                  <div class="text d-flex justify-content-center h-100 align-items-center px-5">
                      <span class="icon icon-home_icon_6 text-purple"></span>
                      <div class="mt-4 text-start">
                          <span class="h4 text-purple">{{ $t('adv1') }}</span>
                          <p class="text-purple">{{ $t('adv2') }}</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="col-lg-4 col-md-12 p-0">
          <div class="d-flex flex-column-reverse">
              <div class="col-12 p-0">
                  <img class="w-100" src="./../assets/images/home_5.jpg">
              </div>
              <div class="col-12 icon-text bg-light-gray">
                  <div class="text d-flex justify-content-center h-100 align-items-center px-5">
                      <span class="icon icon-home_icon_4 text-purple"></span>
                      <div class="mt-4 text-start">
                          <span class="h4 text-purple">{{ $t('adv3') }}</span>
                          <p class="text-purple">{{ $t('adv4') }}</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="col-lg-4 col-md-12 p-0">
          <div class="d-flex reverse">
              <div class="col-12 p-0">
                  <img class="w-100" src="./../assets/images/home_6.jpg">
              </div>
              <div class="col-12 icon-text bg-light-gray d-flex justify-content-center">
                  <div class="text d-flex justify-content-center h-100 align-items-center px-5">
                      <span class="icon icon-home_icon_5 text-purple"></span>
                      <div class="mt-4 text-start">
                          <span class="h4 text-purple">{{ $t('adv5') }}</span>
                          <p class="text-purple">{{ $t('adv6') }}</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>

    <div id="btop" class="row w-100 m-0">
        <div class="col-lg-6 col-md-12 col-sm-12">
            <img id="img7" src="./../assets/images/home_7.png">
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12">
            <h2 class="text-white text-start">{{ $t('ai3') }}</h2>
            <h2 class="text-white text-start">{{ $t('ai4') }}</h2>
        </div>
    </div>

    <div id="img8-1" class="pt-5 pb-5">
      <div class="container pt-5 pb-5">
          <div class="row">
              <div id="people1" class="people col-3 img-with-text-img8 p-0">
                  <img class="w-100 nohover" src="./../assets/images/home_people1.jpg">
                  <img class="w-100 hover" src="./../assets/images/home_people1_1.jpg">
                  <div class="text-on-img text4 w-100 nohover">
                      <h4 class="text-white text-center m-0">{{ $t('dentisit1') }}</h4>
                  </div>
                  <div class="text-on-img text5 w-100 hover animate__animated animate__fadeInUp">
                      <h4 class="text-white text-center">{{ $t('dentisit1') }}</h4>
                      <p class="text-white text-center pl-3 pr-3">{{ $t('dentisit2') }}</p>
                  </div>
              </div>
              <div id="people2" class="people col-3 img-with-text-img8 p-0">
                  <img class="w-100 nohover" src="./../assets/images/home_people2.jpg">
                  <img class="w-100 hover" src="./../assets/images/home_people2_1.jpg">
                  <div class="text-on-img text4 w-100 nohover">
                      <h4 class="text-white text-center m-0">{{ $t('Finance1') }}</h4>
                  </div>
                  <div class="text-on-img text5 w-100 hover animate__animated animate__fadeInUp">
                      <h4 class="text-white text-center">{{ $t('Finance1') }}</h4>
                      <p class="text-white text-center pl-3 pr-3">{{ $t('Finance2') }}</p>
                  </div>
              </div>
              <div id="people3" class="people col-3 img-with-text-img8 p-0">
                  <img class="w-100 nohover" src="./../assets/images/home_people3.jpg">
                  <img class="w-100 hover" src="./../assets/images/home_people3_1.jpg">
                  <div class="text-on-img text4 w-100 nohover">
                      <h4 class="text-white text-center m-0">{{ $t('consult1') }}</h4>
                  </div>
                  <div class="text-on-img text5 w-100 hover animate__animated animate__fadeInUp">
                      <h4 class="text-white text-center">{{ $t('consult1') }}</h4>
                      <p class="text-white text-center pl-3 pr-3">{{ $t('consult2') }}</p>
                  </div>
              </div>
              <div id="people4" class="people col-3 img-with-text-img8 p-0">
                  <img class="w-100 nohover" src="./../assets/images/home_people4.jpg">
                  <img class="w-100 hover" src="./../assets/images/home_people4_1.jpg">
                  <div class="text-on-img text4 w-100 nohover">
                      <h4 class="text-white text-center m-0">{{ $t('HR1') }}</h4>
                  </div>
                  <div class="text-on-img text5 w-100 hover animate__animated animate__fadeInUp">
                      <h4 class="text-white text-center">{{ $t('HR1') }}</h4>
                      <p class="text-white text-center pl-3 pr-3">{{ $t('HR2') }}</p>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <!-- mobile -->
    <div id="mycarousel" class="carousel slide" data-bs-ride="carousel">
        <div id="img8" class="carousel-inner pt-5 pb-5">
            <div class="carousel-item active">
                <div id="people1" class="people col-12 img-with-text p-0">
                    <img class="w-100 px-5 nohover mx-auto" src="./../assets/images/home_people1.jpg">
                    <img class="w-100 px-5 hover mx-auto" src="./../assets/images/home_people1_1.jpg">
                    <div class="text-on-img text4 w-100 text-center nohover">
                        <h4 class="text-white text-center m-0">{{ $t('dentisit1') }}</h4>
                    </div>
                    <div class="text-on-img text5 w-100 text-center hover animate__animated animate__fadeInUp">
                        <h4 class="text-white text-center">{{ $t('dentisit1') }}</h4>
                        <p class="text-white text-center mx-5 px-5">{{ $t('dentisit2') }}</p>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div id="people2" class="people col-12 img-with-text p-0">
                    <img class="w-100 px-5 nohover mx-auto" src="./../assets/images/home_people2.jpg">
                    <img class="w-100 px-5 hover mx-auto" src="./../assets/images/home_people2_1.jpg">
                    <div class="text-on-img text4 w-100 text-center nohover">
                        <h4 class="text-white text-center m-0">{{ $t('Finance1') }}</h4>
                    </div>
                    <div class="text-on-img text5 w-100 text-center hover animate__animated animate__fadeInUp">
                        <h4 class="text-white text-center">{{ $t('Finance1') }}</h4>
                        <p class="text-white text-center mx-5 px-5">{{ $t('Finance2') }}</p>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div id="people3" class="people col-12 img-with-text p-0">
                    <img class="w-100 px-5 nohover mx-auto" src="./../assets/images/home_people3.jpg">
                    <img class="w-100 px-5 hover mx-auto" src="./../assets/images/home_people3_1.jpg">
                    <div class="text-on-img text4 w-100 text-center nohover">
                        <h4 class="text-white text-center m-0">{{ $t('consult1') }}</h4>
                    </div>
                    <div class="text-on-img text5 w-100 text-center hover animate__animated animate__fadeInUp">
                        <h4 class="text-white text-center">{{ $t('consult1') }}</h4>
                        <p class="text-white text-center mx-5 px-5">{{ $t('consult2') }}</p>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div id="people4" class="people col-12 img-with-text p-0">
                    <img class="w-100 px-5 nohover mx-auto" src="./../assets/images/home_people4.jpg">
                    <img class="w-100 px-5 hover mx-auto" src="./../assets/images/home_people4_1.jpg">
                    <div class="text-on-img text4 w-100 text-center nohover">
                        <h4 class="text-white text-center m-0">{{ $t('HR1') }}</h4>
                    </div>
                    <div class="text-on-img text5 w-100 text-center hover animate__animated animate__fadeInUp">
                        <h4 class="text-white text-center">{{ $t('HR1') }}</h4>
                        <p class="text-white text-center mx-5 px-5">{{ $t('HR2') }}</p>
                    </div>
                </div>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#mycarousel" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#mycarousel" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>

    <div class="ptob p-3">
      <h2 class="text-white m-0">{{ $t('Device') }}</h2>
      <div class="mb-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="20">
          <line class="left-line" x1="0" y1="0" x2="40" y2="20" stroke="white" />
          <line class="right-line" x1="0" y1="20" x2="40" y2="0" stroke="white" />
        </svg>
      </div>
      <img id="omni-logo" src="./../assets/images/OMNI_logo_W.png">
    </div>

    <div class="img-with-text">
      <img id="observimg" class="w-100" src="./../assets/images/home_10.jpg">
      <div id="text7" class="text-on-img text-start">
        <img class="w-75 mb-4" src="./../assets/images/OBSERV_logo.png">
        <h3 class="text-purple text-start">{{ $t('Device1') }}</h3>
      </div>
      <div id="circle" class="lg-row text-on-img">
        <div class="col-3 mx-2" data-aos="flip-left">
          <img class="w-100" src="./../assets/images/OBSERV_circle1.png">
        </div>
        <div class="col-3 mx-2" data-aos="flip-left">
          <img class="w-100" src="./../assets/images/OBSERV_circle2.png">
        </div>
        <div class="col-3 mx-2" data-aos="flip-left">
          <img class="w-100" src="./../assets/images/OBSERV_circle3.png">
        </div>
        <div class="col-3 mx-2" data-aos="flip-left">
          <img class="w-100" src="./../assets/images/OBSERV_circle4.png">
        </div>
      </div>
    </div>
    <div class="sm-row justify-content-around py-2 px-5">
      <div class="col-2 p-0">
        <img class="w-100" src="./../assets/images/OBSERV_circle1.png">
      </div>
      <div class="col-2 p-0">
        <img class="w-100" src="./../assets/images/OBSERV_circle2.png">
      </div>
      <div class="col-2 p-0">
        <img class="w-100" src="./../assets/images/OBSERV_circle3.png">
      </div>
      <div class="col-2 p-0">
        <img class="w-100" src="./../assets/images/OBSERV_circle4.png">
      </div>
    </div>

    <div class="sm text-start bg-black p-4">
        <img class="w-75 mb-4" src="./../assets/images/OBSERV_logo.png">
        <h4 class="text-light-blue">{{ $t('Daylight') }} <span class="text-white">{{ $t('Daylight1') }}</span></h4>
        <h4 class="text-light-blue">{{ $t('UV') }} <span class="text-white">{{ $t('UV1') }}</span></h4>
        <h4 class="text-light-blue">{{ $t('Wood') }} <span class="text-white">{{ $t('Wood1') }}</span></h4>
        <img id="sm-img10-1" src="./../assets/images/OBSERV_01.png">
    </div>
    <div class="img-with-text bg-black px-5">
      <img id="img10" class="float-end" src="./../assets/images/OBSERV_02.png">
      <div id="text8" class="lg text-on-img text-start">
        <img class="w-75 mb-4" src="./../assets/images/OBSERV_logo.png">
        <h4 class="text-light-blue">{{ $t('Daylight') }} <span class="text-white">{{ $t('Daylight1') }}</span></h4>
        <h4 class="text-light-blue">{{ $t('UV') }} <span class="text-white">{{ $t('UV1') }}</span></h4>
        <h4 class="text-light-blue">{{ $t('Wood') }} <span class="text-white">{{ $t('Wood1') }}</span></h4>
      </div>
      <div id="img10-1" class="lg text-on-img">
        <img src="./../assets/images/OBSERV_01.png">
      </div>
      <div id="text9" class="text-on-img text-start text-white" data-aos="fade-left">
        <div class="row">
            <h3 class="text-white m-0 d-flex align-items-center"></h3>
        </div>
        <div class="row" v-for="(light, i) in icons" :key="light">
            <h3 class="text-white m-0 d-flex align-items-center">
                <span class="icon me-4" :class="light"></span>
                {{ $t(`mode${i+1}`) }}
            </h3>
        </div>
      </div>
    </div>

    <div class="row my-5 justify-content-center">
        <div class="col-lg-auto col-md-10 mt-3">
            <img class="w-100" src="./../assets/images/OMNI_pic_1.png">
        </div>
        <div class="col-lg-auto col-md-10 mt-3">
            <img class="w-100" src="./../assets/images/OMNI_pic_2.png">
        </div>
        <div class="col-lg-auto col-md-10 mt-3">
            <img class="w-100" src="./../assets/images/OMNI_pic_3.png">
        </div>
    </div>

    <div class="img-with-text">
        <img id="img11" src="./../assets/images/home_11.jpg">
        <div id="text10" class="text-on-img w-100">
            <h3 class="text-purple">{{ $t('patent1') }}</h3>
            <div class="row justify-content-center mt-3">
                <div class="col-lg-auto col-md-10 mt-3">
                    <img class="w-75" src="./../assets/images/beauty_logo.png">
                </div>
                <div class="col-lg-auto col-md-10 mt-3">
                    <img class="w-100" src="./../assets/images/logo_2.png">
                    <p class="px-2 text-center">{{ $t('patent2') }}</p>
                </div>
            </div>
        </div>
    </div>

    <div class="btop p-5">
        <div class="row justify-content-center">
            <div class="col-lg-5 col-md-10 mt-3 text-start">
                <h4 class="text-white text-start">{{ $t('CPFA1') }}</h4>
                <img class="w-75" src="./../assets/images/OBSERV_logo_w.png">
                <video controls controlsList="nodownload" class="w-100 mt-3 pe-3">
                    <source src="@/assets/videos/video1.mp4" type="video/mp4">
                </video>
            </div>
            <div class="col-lg-4 col-md-10 ms-3 mt-3 align-self-end position-relative">
                <div id="pad" class="lg text-on-img">
                    <img class="w-100" src="./../assets/images/OBSERV_pad.png">
                </div>
                <h3 class="text-white text-start mb-4">{{ $t('CPFA2') }}</h3>
                <p class="mt-3 text-start text-white">
                    <span class="text-light-blue">{{ $t('CPFA3') }}</span>{{ $t('CPFA4') }}
                    <span class="text-light-blue">{{ $t('CPFA5') }}</span>{{ $t('CPFA6') }}
                    <span class="text-light-blue">{{ $t('CPFA7') }}</span>{{ $t('CPFA8') }}
                    <span class="text-light-blue">{{ $t('CPFA9') }}</span>{{ $t('CPFA10') }}
                    {{ $t('CPFA11') }}
                    <span class="text-light-blue">{{ $t('CPFA12') }}</span>{{ $t('CPFA13') }}
                    {{ $t('CPFA14') }}
                    <span class="text-light-blue">{{ $t('CPFA15') }}</span>
                    {{ $t('CPFA16') }}
                    {{ $t('CPFA17') }}
                </p>
            </div>
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-5 align-self-center sm">
            <img class="w-100" src="./../assets/images/prs_logo.png">
        </div>
    </div>
    <div class="img-with-text">
        <img id="img12" class="" src="./../assets/images/home_12.jpg">
        <div id="prs" class="text-on-img w-100">
            <div class="row justify-content-center">
                <div class="col-lg-5 col-md-8" data-aos="fade-up">
                    <img class="w-100" src="./../assets/images/prs_dr.png">
                </div>
                <div class="col-3 align-self-center lg">
                    <img class="w-100" src="./../assets/images/prs_logo.png">
                </div>
            </div>
        </div>
    </div>

    <div class="img-with-text">
        <img id="img9" class="" src="./../assets/images/home_9.jpg">
        <div id="text6" class="text-on-img w-100">
            <h2 class="text-white text-center m-0">{{ $t('system1') }}</h2>
            <h2 class="text-white text-center mb-5">{{ $t('system2') }}</h2>
            <p class="text-white text-center">{{ $t('system3') }}</p>
            <div class="d-flex justify-content-center">
                <router-link to="/contact" id="contact-btn" class="p-1 pl-3">
                  <span class="align-middle">{{ $t('system4') }}</span>
                </router-link>
            </div>
        </div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      icons: [
        'icon-light_daylight',
        'icon-light_texture',
        'icon-light_pigment',
        'icon-light_redness',
        'icon-light_PP',
        'icon-light_CP',
        'icon-light_UV',
        'icon-light_woods'
      ]
    }
  },
  mounted () {
    document.title = this.$t('home') + ' - OMNIcounsel'
  }
}
</script>
<style lang="scss">
@import '@/scss/theme.scss';
#main {
.img-with-text{
  position: relative;
  width: 100%;
  overflow: hidden;
}
.img-with-text-img8{
  position: relative;
  overflow: hidden;
}
.text-on-img{
  position: absolute
}
.ptob {
  background: linear-gradient(to right, $purple-two, $light-blue);
}
.btop {
  background: linear-gradient(to right, $light-blue, $purple-two);
}
#text1{
  top: 36%;
  left: 15%;
  width: 31%;
  @include media-lgnb{
      top: 35%;
      left: 6%;
      width: 40%;
  }
  @include media-nb{
      h2{
          font-size: 1.6rem;
      }
      h4{
          font-size: 1.2rem;
          padding-bottom: 1rem !important;
      }
      top: 34%;
      left: 2%;
      width: 40%;
  }
  @include media-pad{
      top: 37%;
      left: 1%;
      width: 48%;
  }
  @include media-mobile{
      top: 30%;
      left: 5%;
      width: 58%;
      h2{
          font-size: 1.5rem;
      }
  }
}
#ptob{
  background: linear-gradient(to right, $purple-two, $light-blue);
  @include media-pc{
      padding-left: 15rem;
      padding-right: 15rem;
  }
  @include media-lgnb{
      padding-left: 2rem;
      padding-right: 2rem;
  }
  @include media-nb{
      padding-left: 0;
      padding-right: 0;
  }
  @include media-mobile{
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
  }
  .border-right{
      @include media-pc{
          border-right: 1px solid white;
      }
      @include media-mobile{
          border-right: none !important;
      }
  }
  .icon{
      font-size: 150px;
      @include media-mobile{
          font-size: 80px;
      }
  }
  h4{
      width: 100%;
      @include media-mobile{
          width: 100%;
          font-size: 0.8rem;
          padding-top: 0 !important;
      }
  }
}
#text2{
  top: 17%;
  right: 10%;
  width: 40%;
  @include media-nb{
      top: 12%;
      right: 5%;
      width: 48%;
  }
}
.img-with-text{
  @include media-mobile{
      display: block !important;
  }
}
#text3{
  background-image: url("./../assets/images/home_circle.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  top:0;
  padding-top: 17%;
  width: 30%;
  left: 22%;
  @include media-pc{
      h2{
          font-size: 3rem;
      }
      h4{
          font-size: 2rem;
      }
  }
  @include media-nb{
      width: 50%;
      padding-top: 16%;
      left: 20%;
  }
  @include media-pad{
      h1{
          font-size: 2.3rem;
      }
      h2{
          font-size: 2rem;
      }
      left: 22%;
  }
  @include media-mobile{
      h1{
          font-size: 1.3rem;
          line-height: 1.2;
      }
      h4{
          font-size: 1.1rem;
          line-height: 1.2;
      }
      width: 100%;
      padding-top: 15%;
      left: 24%;
  }
}
.icon-text{
  position: relative;
  padding-top: 62.9%;
  // padding: 124px;
  .icon{
      font-size: 100px;
      @include media-mobile{
          font-size: 70px;
      }
  }
  .text{
      position: absolute;
      top: 0;
      left: 0;
      p{
          font-family: PingFangTC, sans-serif;
      }
      @include media-nb{
          // padding: 0.5rem;
          // margin: 0.5rem;
          h4{
              font-size: 1.4rem;
              margin-bottom: 0;
              padding-top: 0.5rem !important;
          }
      }
  }
}
.reverse{
  flex-direction: column;
  @include media-pad{
      flex-direction: column-reverse;
  }
}

#btop{
  @include media-pad{
      margin-top: 15rem !important;
  }
  @include media-mobile{
      margin-top: 8rem !important;
  }
  .col-lg-6{
      padding-top: 3rem;
      padding-bottom: 3rem;
      @include media-nb{
          padding-top: 2rem;
          padding-bottom: 2rem;
      }
      @include media-pad{
          padding-top: 3rem;
          padding-bottom: 1rem;
          text-align: center;
      }
      // @include media-mobile
      //     padding-top: 3rem;
      //     padding-bottom: 1rem;
      //     text-align: center;
      // }
  }
  position: relative;
  background: linear-gradient(to right, $light-blue, $purple-two);
  #img7{
      position: absolute;
      z-index: 1;
      height: 150%;
      top: -25%;
      left: 15%;
      @include media-nb{
          left: 0;
      }
      @include media-pad{
          width: 75%;
          height: auto;
          margin-left: auto;
          margin-right: auto;
          top: -15rem;
      }
      @include media-mobile{
          width: 100%;
          top: -8rem;
      }
  }
}
#img8-1{
  @include media-pc{
      display: block;
  }
  @include media-mobile{
      display: none;
  }
}
#mycarousel{
  @include media-pc{
      display: none;
  }
  @include media-mobile{
      display: block;
  }
}
#img8, #img8-1{
  background-image: url("./../assets/images/home_8.jpg");
  .row{
      padding-top: 3rem;
      @include media-nb{

      }
  }
  .hover{
      display: none;
  }
  .nohover{
      img{
          display: flex;
      }
      h4{
          line-height: 2;
          @include media-nb{
              line-height: 1.8;
          }
          @include media-pad{
              line-height: 1.5;
          }
          @include media-mobile{
              line-height: 2.5;
          }
      }
  }
  .text4{
      // background: linear-gradient(to right, $purple-two, $light-blue);
      // opacity: 0.7;
      bottom: 0;
  }
  .text5{
      bottom: 0;
      height: 60%;
  }
}
#img9{
  width: 100%;
  @include media-nb{
      width: auto;
  }
}
#text6{
  bottom: 0;
  height: 75%;
  @include media-pad{
      height: 75%;
  }
  #contact-btn{
      &:hover{
          background-color: rgba(white, 0.5);
      }
      font-family: PingFangTC-Medium;
      color: white;
      border: 1px solid white;
      text-decoration: none;
      border-radius: 50px;
      .icon-arrow-right2{
          background-color: white;
          color: #3c4eb5;
          border-radius: 50%;
          font-size: 30px;
      }
  }
}
#omni-logo {
  @include media-mobile {
    width: 40vw;
  }
}
#text7 {
  width: 38%;
  top: 17%;
  left: 15%;
  @include media-pad {
    width: 50%;
    top: 25%;
    h3 {
      line-height: 1;
    }
  }
}
#observimg {
  @include media-nb{
      width: auto;
  }
}
#circle {
  width: 45%;
  bottom: 20%;
  left: 15%;
  @include media-nb{
      width: 40%;
      bottom: 20%;
      left: 15%;
  }
}
#img10 {
  height: 80vh;
  @include media-mobile {
    // width: 80vw;
    height: 60vh;
  }
}
#img10-1 {
  img {
    height: 45vh;
  }
  left: 10%;
  bottom: 5%;
}
#sm-img10-1 {
    width: 100vw;
}
#text8 {
  width: 38%;
  top: 10%;
  left: 15%;
  @include media-pad {
    h3 {
      line-height: 1;
    }
  }
}
#text9 {
    top: 0;
    left: 58%;
    height: 80vh;
    @include media-pad {
        left: 17%;
    }
    @include media-mobile {
        left: 10%;
        height: 60vh;
    }
    .row {
        height: 9.75%
    }
    .icon {
        font-size: 3rem;
        @include media-mobile {
            font-size: 1.8rem;
        }
    }
}
#text10 {
    top: 25%;
}
#pad {
    bottom: 100%;
    left: 20%;
    width: 35vh;
}
#img12 {
    width: 90vw;
    @include media-pad {
        width: 125vw;
    }
}
#prs {
    bottom: 0;
}
}
</style>
