<template>
  <div id="contact">
    <div class="container mt-5 mb-5">
        <h3 class="text-dark-purple text-start">{{ $t('contact') }}</h3>
        <p class="text-gray mb-5">{{ $t('contact1') }}</p>
        <form ref="form" @submit.prevent="">
            <div class="row">
                <!-- 姓氏 -->
                <div class="col-lg-6 col-sm-12 mt-3">
                    <p class="text-dark-purple m-0">{{$t('contact2')}}</p>
                    <input class="w-100" type="text" v-model="lastname" name="lastname" placeholder="" required="required">
                </div>
                <!-- 名字 -->
                <div class="col-lg-6 col-sm-12 mt-3">
                    <p class="text-dark-purple m-0">{{$t('contact3')}}</p>
                    <input class="w-100" type="text" v-model="firstname" name="firstname" placeholder="" required="required">
                </div>
                <!-- 性別 -->
                <div class="col-lg-6 col-sm-12 mt-3">
                    <p class="text-dark-purple m-0">{{$t('contact4')}}</p>
                    <input class="w-100" type="text" v-model="gender" name="gender" placeholder="" required="required">
                </div>
                <!-- 電話號碼 -->
                <div class="col-lg-6 col-sm-12 mt-3">
                    <p class="text-dark-purple m-0">{{$t('contact5')}}</p>
                    <input class="w-100" type="text" v-model="phone" name="phone" placeholder="" required="required">
                </div>
                <!-- email -->
                <div class="col-lg-6 col-sm-12 mt-3">
                    <p class="text-dark-purple m-0">{{$t('contact6')}}</p>
                    <input class="w-100" type="email" v-model="email" name="email" placeholder="" required="required">
                </div>
                <!-- 公司名稱 -->
                <div class="col-lg-6 col-sm-12 mt-3">
                    <p class="text-dark-purple m-0">{{$t('contact7')}}</p>
                    <input class="w-100" type="text" v-model="org" name="org" placeholder="" required="required">
                </div>
                <!-- 公司類別 -->
                <div class="col-lg-6 col-sm-12 mt-3">
                    <p class="text-dark-purple m-0">{{$t('contact8')}}</p>
                    <input class="w-100" type="text" v-model="org_type" name="org_type" placeholder="" required="required">
                </div>
                <!-- 職稱 -->
                <div class="col-lg-6 col-sm-12 mt-3">
                    <p class="text-dark-purple m-0">{{$t('contact9')}}</p>
                    <input class="w-100" type="text" v-model="job_title" name="job_title" placeholder="" required="required">
                </div>
                <!-- 城市 -->
                <div id="region" class="col-lg-6 col-sm-12 mt-3">
                    <p class="text-dark-purple m-0">{{$t('contact10')}}</p>
                    <region-select class="w-100" v-model="region" :country="country" :region="region" autocomplete=true />
                </div>
                <!-- 國家 -->
                <div id="country" class="col-lg-6 col-sm-12 mt-3">
                    <p class="text-dark-purple m-0">{{$t('contact11')}}</p>
                    <country-select class="w-100" v-model="country" :country="country" autocomplete=true />
                </div>
                <!-- 內容 -->
                <div class="col-12 mt-3">
                    <p class="text-dark-purple m-0">{{$t('contact12')}}</p>
                    <textarea class="w-100" type="text" id="content" name="content" placeholder="" required="required"></textarea>
                </div>
                <div class="col-12 mt-3 mb-3 d-flex justify-content-end">
                    <a id="submit-btn" class="p-1 px-3" role="button"
                        :class="{'bg-gray': sending, 'bg-purple': !sending}"
                        @click="send()"
                    ><span class="align-middle">{{$t('contact13')}}</span>
                    </a>
                </div>
            </div>
        </form>
    </div>
    <hr class="mt-5 bg-purple">
  </div>
</template>
<script>
import emailjs from 'emailjs-com'
export default {
  data () {
    return {
      lastname: '',
      firstname: '',
      gender: '',
      phone: '',
      email: '',
      org: '',
      org_type: '',
      job_title: '',
      country: '',
      region: '',
      content: '',
      sending: false
    }
  },
  mounted () {
    document.title = this.$t('contact') + ' - OMNIcounsel'
  },
  methods: {
    send () {
      this.sending = true
      emailjs.sendForm(
        'service_tynu61b',
        'template_wlhokaz',
        this.$refs.form,
        'user_zW6RPJq0TukD0OnNGPdrX'
      ).then((result) => {
        this.sending = false
        alert('已送出！')
      }, (error) => {
        this.sending = false
        alert('寄送失敗！')
        console.log(error)
      })
    }
  }
}
</script>
<style lang="scss">
@import '@/scss/theme.scss';
#contact{
    .container{
        @include media-pc{
            max-width: 50%;
        }
        @include media-lgnb{
            max-width: 75%;
        }
        .row{
            font-family: PingFangTC-Medium;
            textarea{
                min-height: 200px;
            }
            #submit-btn{
                color: white;
                text-decoration: none;
                border-radius: 50px;
                .icon-arrow-right2{
                    background-color: white;
                    color: $purple;
                    border-radius: 50%;
                    font-size: 30px;
                }
            }
        }
    }
    hr{
        @include media-pc{
            margin-left: 15rem;
            margin-right: 15rem;
        }
        @include media-lgnb{
            margin-left: 2rem;
            margin-right: 2rem;
        }
        @include media-nb{
            margin-left: 0;
            margin-right: 0;
        }
    }
}
</style>
