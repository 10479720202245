export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
  "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "zh-TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["繁體中文"])},
  "zh-CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["简体中文"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright © OMNI COUNSEL All rights reserved"])},
  "footer1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["遠京企業股份有限公司 MYGUARD CO., LTD."])},
  "footer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3F.-2, No.146, Wenxing Rd.,Guishan, Taoyuan City 33377, Taiwan"])},
  "footer3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+886-3-327-0080"])},
  "omni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are micro expressions？"])},
  "omni1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["霎時之誠-臉部瞬間的微表情不經意間透露了您的真實情緒"])},
  "omni2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micro expressions are facial expressions that occur within a fraction of a second. This involuntary emotional leakage exposes a person's true emotions."])},
  "omni3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMNIcounsel is an emotion AI system which help to evaluate and quantify the micro expressions of aesthetic concern. With the help of this avantgarde device, physicians are more likely to optimize the treatment with minimal intervention and lead to natural results. "])},
  "icon1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["功能性解剖學為基礎"])},
  "icon2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["精準量化研究實戰兩相宜"])},
  "icon3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合併心理學動作單元分析"])},
  "tech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在科學研究下，"])},
  "tech1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發現人類的表情至少超過10,000種"])},
  "tech2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要花多少時間學習、觀察、客觀的判別？"])},
  "tech3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["何妨交給OMNIcounsel®？幫您以簡馭繁，讓您只需專注在您的專業。"])},
  "tech4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMNIcounsel®基於美國心理學家保羅· 艾克曼(Paul Ekman) 所提出的面部動作編碼系統，將複雜的臉部表情分析歸納出不同的情緒類別。再加上以大數據訓練的人工智慧，哪怕是不易察覺的細微表情，OMNIcounsel®都可以精準捕捉、並將複雜的動作單元化繁為簡，轉為量化的分析結果。"])},
  "ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["彈指之間，深層洞察"])},
  "ai1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI輔助 專業隨行"])},
  "ai1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI輔助"])},
  "ai1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["專業隨行"])},
  "adv1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["數位溝通力"])},
  "adv2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透過AI協助解讀臉部表情背後潛藏的情緒，從而搭建精準溝通的橋樑！"])},
  "adv3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["數位競爭力"])},
  "adv4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["除了微細表情明察秋毫，更可應用於專注度評估，加速工作效率。"])},
  "adv5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["美感執行力"])},
  "adv6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["精準的分析，讓量身打造的美學治療變得可能，以達成最少干預、最佳成果的自然改善。"])},
  "ai3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMNI隨行"])},
  "ai4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["增加專業AI形象"])},
  "dentisit1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["美容醫學從業人員"])},
  "dentisit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["精準掌握患者臉部變化，規劃最合適的治療方案！"])},
  "Finance1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["財經相關人員"])},
  "Finance2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["協助支持你的第六感，找出最值得信賴的夥伴！"])},
  "consult1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["心理諮商師"])},
  "consult2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["精準客觀判斷表情學，掌握更多觀察時間。"])},
  "HR1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人力資源 HR"])},
  "HR2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全新智慧AI科技，不苦惱的找出適合的同事夥伴。"])},
  "Device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skin Diagnostic Device"])},
  "Device1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A multi-modality face illumination and imaging system"])},
  "light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["27"])},
  "light1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["27"])},
  "diffuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18"])},
  "diffuse1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diffuse"])},
  "polarized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09"])},
  "polarized1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["polarized"])},
  "wavelengths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03"])},
  "wavelengths1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wavelengths"])},
  "Daylight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daylight"])},
  "Daylight1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["｜ 4000˚k"])},
  "UV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["True UV"])},
  "UV1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["｜ 365nm"])},
  "Wood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulated Wood’s "])},
  "Wood1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["｜ 365nm+405nm"])},
  "mode1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daylight"])},
  "mode2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surface texture"])},
  "mode3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pigmentation"])},
  "mode4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vascularity"])},
  "mode5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parallel polarised"])},
  "mode6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross polarised"])},
  "mode7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["True UV"])},
  "mode8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woods"])},
  "patent1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們的註冊及專利"])},
  "patent2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["運用於美容醫療的人工智能輔助評估方法及系統與電子裝置"])},
  "CPFA1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["與業界領導品牌合作"])},
  "CPFA2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works?"])},
  "CPFA3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C"])},
  "CPFA4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ustomized"])},
  "CPFA5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P"])},
  "CPFA6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recision"])},
  "CPFA7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["F"])},
  "CPFA8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["acial"])},
  "CPFA9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A"])},
  "CPFA10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ssessment"])},
  "CPFA11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A novel"])},
  "CPFA12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI"])},
  "CPFA13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-assisted protocol to unveil and"])},
  "CPFA14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantify the facial"])},
  "CPFA15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["micro-expressions"])},
  "CPFA16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for"])},
  "CPFA17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["advanced aesthetic treatment"])},
  "system1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMNI AI臉部辨識系統"])},
  "system2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透過微表情，迎接心時代"])},
  "system3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可根據專業別客製化軟體，歡迎與我們聯繫"])},
  "system4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT OMNI"])},
  "about1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "about2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["誠信，"])},
  "about3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們追求以人為本的本質"])},
  "about4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["因此我們希望透過OMNIcounsel®，基於科學基礎提供客觀的輔助，支持各領域與產業的評估工作，從精準判斷進而改善作業流程，讓一切變得更省心。"])},
  "develop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chronicle"])},
  "2016a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2016"])},
  "2016b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-AI Era"])},
  "2016c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2016/10/22-23"])},
  "2016d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual Meeting of Taiwan Academy of Facial Plastic and Reconstructive Surgery (TAFPRS), Taipei. Invited Speech Approach to Overall Facial Harmony by App Platform"])},
  "2018a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2018"])},
  "2018b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI：Pre-OMNIcounsel Era"])},
  "2018c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2018/5/4-6 "])},
  "2018d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aesthetic & Anti-Aging Medicine World Congress Asia (AMWC Asia), Taipei. Invited Speech What your facial features are saying? Application of emotion A.I. system"])},
  "2018e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2018/5/2-5 "])},
  "2018f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["美沃斯國際醫學美容大會，武漢：美人致諮詢教育首次發表"])},
  "2018g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2018/11/9-10 "])},
  "2018h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第十四屆中國皮膚科醫師年會暨全國美容皮膚科學大會，廣州：專題演講，以功能性、動態性、解剖學作為臉部醫美評估的新概念"])},
  "2019a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2019"])},
  "2019b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI: OMNIcounsel Era"])},
  "2019c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2019/4/4-6"])},
  "2019d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aesthetic & Anti-Aging Medicine World Congress (AMWC), Monte Carlo. Invited Speech Impact and application of AI technology in current aesthetic practice "])},
  "2019e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2019/5/10-12"])},
  "2019f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aesthetic Medicine World Congress Asia (AMWC Asia), "])},
  "2019g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taipei. Invited Speech"])},
  "2019h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2019/10/24-27"])},
  "2019i": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual Meeting of American Society for Dermatologic Surgery "])},
  "2019j": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ASDS), Chicago. Conference Paper"])},
  "2019k": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2019/11/8"])},
  "2019l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第十五屆中國醫師協會皮膚科醫師年會，廈門：專題演講"])},
  "2020a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020"])},
  "2020b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI: OMNI Counsel Era"])},
  "2020c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020/1/30-2/1 "])},
  "2020d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International Master Course of Aging Science (IMCAS), "])},
  "2020e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paris. Invited Speech "])},
  "2020f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A novel AI-assisted protocol to unveil and quantify the facial micro-expressions for advanced aesthetic treatment: Integration of AI technology in my aesthetic practice"])},
  "2020g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020/2/6-9"])},
  "2020h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9th World Congress of Facial Plastic Surgery, "])},
  "2020i": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taipei. Invited Speech"])},
  "2020j": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020.3"])},
  "2020k": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plast Reconstr Surg Glob Open 2020 Mar 11;8(3):e2688. "])},
  "2020l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published paper"])},
  "2020m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customized Precision Facial Assessment: An AI-assisted Analysis of Facial Micro-expressions for Advanced Aesthetic Treatment  doi: 10.1097/GOX.0000000000002688 "])},
  "2020n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020/3/20-24"])},
  "2020o": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual Meeting of American Academy of Dermatology (AAD), "])},
  "2020p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denver. Invited Speech  (cancelled due to COVID-19)"])},
  "2020q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020/8/28-30"])},
  "2020r": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12th 5-Continent Congress (5CC) World Congress, Barcelona. Keynote Speech  (turned virtual) How AI-assisted Facial Analysis Aids in Precisional Aesthetic Treatment"])},
  "2020s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020/11/14-15"])},
  "2020t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual Meeting of Taiwan Society of Aesthetic Plastic Surgery (TSAPS), Taipei."])},
  "2020u": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Invited Speech Implication of AI in my aesthetic practice: A Taiwan perspective"])},
  "2020v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020/11/16-20"])},
  "2020w": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KoreaDerma 2020 Virtual. Invited Speech Forehead wrinkles revisited: An anatomy-based, functional approach in Asian"])},
  "2021a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021"])},
  "2021b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021/1"])},
  "2021c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In collaboration with OBSERV®"])},
  "2021d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021/7/22-23"])},
  "2021e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual Meeting of Dermatological Society of Thailand, Bangkok: Invited Speech"])},
  "omnifirst1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMNI 是最初的起點"])},
  "omnifirst2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMNIcounsel®會持續不斷地與不同的產業推出各式應用場景，敬請期待不同的火花。就如同網路改變世界、智慧型手機改變了您我的行為。現在還無法完全被定義的可能，即將陪伴在您我的身邊。"])},
  "omnifirst3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未來已經來到，OMNIcounsel®就在您的身邊。"])},
  "omnifirst4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["讓我們一起見證轉變的時刻，共同邁進到下一個新心組合當中。"])},
  "contact1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill out the form, including details, and we'll be in touch shortly."])},
  "contact2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
  "contact3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
  "contact4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
  "contact5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
  "contact6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "contact7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
  "contact8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industry"])},
  "contact9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Title"])},
  "contact10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "contact11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country/Region"])},
  "contact12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can we help you?"])},
  "contact13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUBMIT"])}
}